import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Img from "gatsby-image"

const paths = {
  it: "/it/blog",
  br: "/br/blog",
  fr: "/fr/blog",
  de: "/de/blog",
  es: "/blog",
}

export default props => (
  <>
    <Layout
      image="/android-chrome-512x512.png"
      lang={props.pageContext.lang}
      title={props.pageContext.lang === "es" ? "Blog con Artículos relacionados con Startups y Emprendimiento":props.pageContext.lang === "it" ? "Blog con Articoli relativi a Startup e Imprenditorialità":props.pageContext.lang === "br" ? "Blog com Artigos relacionados a Startups e Empreendedorismo":props.pageContext.lang === "fr" ? "Blog avec des Articles liés aux Startups et à l'entreprenariat":"Blog mit Artikeln zum Thema Startups und Unternehmertum"}
      description="Blog"
      paths={paths}
      pageType="blog"
    >
      <div className="wrapper">
        <div className="container">
          <div className="row">
            {props.data.allContentfulBlogPost.edges.map(({ node }, index) => {
              return (
                <>
                
                  {index !== 0 && index % 6 === 0 && (
                    <div className="col-sm-12 mt-5">
                      
                      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                        <ins className="adsbygoogle"
                            style={{display:"block"}}
                            data-ad-client="ca-pub-8800979330857975"
                            data-ad-slot="8619443939"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({});
                        </script>

                    </div>
                  )}

                  

                  <div className="col-sm-4 col-lg-4 col-xl-4 mt-5">
                    <Link
                      to={`${getBlogPath(node.lang)}/${node.slug}/`}
                      title={`${getBlogPath(node.lang)}/${node.slug}/`}
                      style={{
                        textDecoration: "none",
                        color: "dimgrey",
                        fontSize: "0.9rem",
                      }}
                    >
                      <div className="card">
                        <Img
                          alt={node.heroImage.title}
                          title={node.heroImage.title}
                          fadeIn="false"
                          loading="eager"
                          style={{ maxWidth: 450, maxHeight: 253 }}
                          fluid={node.heroImage.fluid}
                          fadeIn="false"
                          loading="eager"
                        />
                        <div className="card-body">
                          <h2 className="card-title">{node.title}</h2>
                          <p className="card-text">{node.excerpt}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  </>
)

export const query = graphql`
  query($lang: String!) {
    allContentfulBlogPost(
      sort: { order: DESC, fields: updatedAt }
      filter: { lang: { eq: $lang } }
    ) {
      edges {
        node {
          lang
          title
          excerpt
          description {
            description
          }
          slug
          heroImage {
            title
            fluid(
              maxHeight: 253
              maxWidth: 450
              quality: 90
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
function getBlogPath(lang) {
  return lang === "es" ? "/blog" : `/${lang}/blog`
}
